import '../scss/main.scss';

// import './libs/popper.min.js';
// import 'bootstrap';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';

import './libs/oxid/jqBootstrapValidation.min.patched.js';

import './libs/Hyphenopoly_Loader.js';

import Utils from './modules/Utils';
import HeaderHelper from './modules/HeaderHelper';
import HeaderSearch from './modules/HeaderSearch';
import HeaderNavigation from './modules/HeaderNavigation';
import HeaderMiniBasket from './modules/HeaderMiniBasket';
import HeaderLoginHelper from './modules/HeaderLoginHelper';
// import Breadcrumb from './modules/Breadcrumb';
import SearchForm from './modules/SearchForm';
import SearchResults from './modules/SearchResults';
// import CookieHintHelper from './modules/CookieHintHelper.js'
import ReviveHelper from './modules/ReviveHelper.js';
import AboModelSticky from './modules/AboModelSticky';
import WebVitalsDebugging from './modules/WebVitalsDebugging';
import { sha256, gaSetUserData } from './modules/GaSetUserData';

window.sha256 = sha256;
window.gaSetUserData = gaSetUserData;

window.$ = jQuery;
window.jQuery = jQuery;

new WebVitalsDebugging();

if (document.readyState != 'loading') {
    onDomReady();
} else {
    document.addEventListener('DOMContentLoaded', onDomReady);
}

function onDomReady() {
    new HeaderHelper();
    // new CookieHintHelper();

    if (!document.body.classList.contains('minimalLogin')) {
        window.headerSearch = new HeaderSearch();
        new HeaderNavigation();
        new HeaderMiniBasket();
        new HeaderLoginHelper();
        // new Breadcrumb();
        new SearchForm();
        new ReviveHelper();
        new SearchResults(); // @TODO: Only import on search results page.
    }

    var Hyphenopoly = {
        require: {
            "de": "Silbentrennungsalgorithmus"
        }
    };

    const htmlClassList = document.documentElement.classList;
    const utils = new Utils();

    if (utils.isIE())         htmlClassList.add('ie', 'ie' + utils.getInternetExplorerVersion());
    if (utils.isEdge())       htmlClassList.add('edge');
    if (utils.isGC())         htmlClassList.add('gc', 'gc' + utils.getGoogleChromeVersion());
    if (utils.isiOS())        htmlClassList.add('ios', 'ios' + utils.getiOSVersion());

    const newsTiles = document.querySelectorAll('.newsTilesContainer');
    if (newsTiles.length > 0) {
        Promise.all([
            import('../scss/_lineicons-pro-regular.scss'),
            import('../scss/_news-tiles.scss'),
            import('./modules/NewsTiles'),
        ]).then((values) => {
            const NewsTiles = values[2].default;
            new NewsTiles();
        });
    }

    const tables = document.getElementsByTagName('table');
    if (tables.length > 0) {
        Promise.all([
            import('./modules/TableHelper'),
        ]).then((values) => {
            const TableHelper = values[0].default;
            new TableHelper();
        });
    }

    const author = document.querySelectorAll('.toggleLinkAuthor');
    const mod = document.getElementById('authorModal');
    if (author.length > 0) {
        Promise.all([
            import('./modules/AuthorModal'),
        ]).then((values) => {
            const AuthorModal = values[0].default;
            new AuthorModal(author, mod);
        });
    }

    const whslider = document.querySelectorAll('.workhelperSliderWrapper');
    if (whslider.length > 0) {
        Promise.all([
            import('./modules/SliderWorkHelper'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('swiper/scss/pagination'),
            import('./modules/SliderNavigationHelper')
        ]).then((values) => {
            const SliderWorkHelper = values[0].default;
            SliderWorkHelper.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[1].Pagination,
                values[5].default
            );

            whslider.forEach((item) => new SliderWorkHelper(item));
        });
    }

    const rtslider = document.querySelectorAll('.relatedTopicsSliderWrapper');
    if (rtslider.length > 0) {
        Promise.all([
            import('./modules/SliderRelatedTopics'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper')
        ]).then((values) => {
            const SliderRelatedTopics = values[0].default;
            SliderRelatedTopics.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );

            rtslider.forEach((item) => new SliderRelatedTopics(item));
        });
    }

    const ptslider = document.querySelectorAll('.producttipSliderWrapper');
    if (ptslider.length > 0) {
        Promise.all([
            import('./modules/SliderProductTip'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper')
        ]).then((values) => {
            const SliderProductTip = values[0].default;
            SliderProductTip.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );

            ptslider.forEach((item) => new SliderProductTip(item));

            $(document).ajaxComplete(() => {
                document
                    .querySelectorAll('.producttipSliderWrapper')
                    .forEach((item) => new SliderProductTip(item))
                ;
            });
        });
    }

    const loginInfoLayerModal = document.querySelectorAll('#loginInfoLayerModal');
    if (loginInfoLayerModal.length > 0) {
        let $loginInfoLayerModal = $(loginInfoLayerModal);
        let $body = $('body');

        $loginInfoLayerModal.css('opacity', 0.3);
        $body.append('<div class="loader" />');

        let ajaxPromise = new Promise(function(resolve, reject) {
            // let xhr = new XMLHttpRequest();

            // xhr.addEventListener('progress', function(e) {
            //     // TODO: What do we do with the (drunken) progress?
            //     console.dir(e);
            //     console.dir({
            //         loaded: e.loaded,
            //         total: e.total,
            //     });
            //     console.log('Ajax progress: ', e.loaded / e.total * 100);
            // });

            // xhr.addEventListener('load', function() {
            //     let html = this.responseXML;
            //     // let fragment = new DocumentFragment();
            //     // fragment.appendChild(html);
            //     // $loginInfoLayerModal.html(fragment.querySelectorAll('#loginInfoLayerModal')[0].innerHTML);
            //     console.dir(html);
            //     $loginInfoLayerModal.html(html.querySelectorAll('#loginInfoLayerModal')[0].innerHTML);

            //     $loginInfoLayerModal.css('opacity', 1);
            //     $body.find('.loader').remove();
            //     $loginInfoLayerModal.modal('show');
            //     resolve();
            // });

            // xhr.addEventListener('error', function() {
            //     // TODO?
            // });

            // xhr.addEventListener('abort', function() {
            //     // TODO?
            // });

            // xhr.open('GET', location.protocol + window.loginLayerUrl);
            // xhr.responseType = 'document';
            // xhr.send();

            $loginInfoLayerModal.load(location.protocol + window.loginLayerUrl + '#loginInfoLayerModal', function() {
                $loginInfoLayerModal.css('opacity', 1);
                $body.find('.loader').remove();
                $loginInfoLayerModal.modal('show');
                resolve();
            });
        });

        Promise.all([
            import('./modules/SliderMyDownloads'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper'),
            ajaxPromise
        ]).then((values) => {
            const SliderMyDownloads = values[0].default;
            SliderMyDownloads.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );
            let myDownloadsSlider = document.querySelectorAll('.myDownloadsSliderWrapper');
            myDownloadsSlider.forEach((item) => new SliderMyDownloads(item));
        });
    }

    const seminarslider = document.querySelectorAll('.seminarSliderWrapper');
    if (seminarslider.length > 0) {
        Promise.all([
            import('./modules/SliderSeminar'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper')
        ]).then((values) => {
            const SliderSeminar = values[0].default;
            SliderSeminar.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );

            seminarslider.forEach((item) => new SliderSeminar(item));
        });
    }

    const newsSingle = document.querySelectorAll('.news-single');
    if (newsSingle.length > 0) {
        Promise.all([
            import('../scss/_news-detail.scss'),
            import('./modules/NewsDetail'),
        ]).then((values) => {
            const NewsDetails = values[1].default;
            new NewsDetails();
        });
    }

    const newsList = document.querySelectorAll('.news-list-view');
    if (newsList.length > 0) {
        Promise.all([
            import('./modules/NewsList'),
        ]).then((values) => {
            const NewsList = values[0].default;
            newsList.forEach((item) => new NewsList(item));
        });
    }

    const newsTabs = document.querySelectorAll('.newsTabsContainer');
    if (newsTabs.length > 0) {
        Promise.all([
            // import('../scss/_news-tabs.scss'), // This has been moved to preload.scss.
            import('bootstrap/js/dist/tab'),
            import('./modules/NewsTabs'),
        ]).then((values) => {
            // Show first tab
            $(newsTabs).find('.nav-tabs a:first').tab('show');

            const NewsTabs = values[1].default;
            window.newsTabs = new NewsTabs();
        });
    }

    const newsLists = document.querySelectorAll('.newsListContainer');
    if (newsLists.length > 0) {
        Promise.all([
            import('../scss/_news-list.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const aToZListContainer = document.querySelectorAll('.aToZListContainer');
    if (aToZListContainer.length > 0) {
        Promise.all([
            import('../scss/_news-atoz.scss'),
            import('./modules/NewsAToZ'),
        ]).then((values) => {
            const NewsAToZ = values[1].default;
            aToZListContainer.forEach((item) => new NewsAToZ(item));
        });
    }

    // const accountDashboard = document.querySelectorAll('.accountDashboardView');
    // if (accountDashboard.length > 0) {
    //     Promise.all([
    //         import('../scss/_account.scss'),
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }

    // const myAccount = document.querySelectorAll('.cl-forgotpwd, .cl-account_password, .accountLoginView');
    // if (myAccount.length > 0) {
    //     Promise.all([
    //         // import('../scss/_account.scss'),
    //         // import('../scss/_forms.scss')
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }

    const expertModalToggle = document.querySelectorAll('.expertModalToggler');
    // const expertModal = document.querySelectorAll('#expertModal');
    const expertModal = document.getElementById('expertModal');
    if (expertModalToggle.length > 0) {
        Promise.all([
            import('../scss/_experts-and-consultants.scss'),
            import('./modules/ExpertModal'),
            // import('./modules/AuthorModal'),
            import('../scss/_news-list.scss'),
        ]).then((values) => {
            const ExpertModal = values[1].default;
            // const AuthorModal = values[2].default;
            new ExpertModal();
            // new AuthorModal(expertModalToggle, expertModal);
        });
    }

    const cooperationitem = document.querySelectorAll('.cooperationitem');
    if (cooperationitem.length > 0) {
        Promise.all([
            import('../scss/_experts-and-consultants.scss'),
            import('../scss/_news-list.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const expertsTileSlider = document.querySelectorAll('.expertTeaserSlideSwiperWrapper');
    if (expertsTileSlider.length > 0) {
        Promise.all([
            import('./modules/SliderExpertsTile'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('swiper/scss/pagination'),
            import('./modules/SliderNavigationHelper'),
            import('swiper/scss/grid'),
        ]).then((values) => {
            const SliderExpertsTile = values[0].default;
            SliderExpertsTile.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[1].Pagination,
                values[1].Grid,
                values[5].default
            );

            expertsTileSlider.forEach((item) => new SliderExpertsTile(item));
        });
    }

    const fullwidthSlider = document.querySelectorAll('.fullwidthTeaserSliderWrapper');
    if (fullwidthSlider.length > 0) {
        Promise.all([
            import('./modules/SliderFullwidth'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('swiper/scss/pagination'),
            import('./modules/SliderNavigationHelper'),
            import('../scss/_fullwidth-slider.scss'),
            import('swiper/scss/autoplay'),
        ]).then((values) => {
            const SliderFullwidth = values[0].default;
            SliderFullwidth.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[1].Pagination,
                values[1].Autoplay,
                values[5].default
            );

            fullwidthSlider.forEach((item) => new SliderFullwidth(item));
        });
    }

    const textWithIconAboveContainer = document.querySelectorAll('.textWithIconAboveContainer');
    if (textWithIconAboveContainer.length > 0) {
        Promise.all([
            import('../scss/_lineicons-pro-regular.scss'),
            // import('../scss/_text-with-icon-above.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const rootlineContainerWrap = document.querySelectorAll('.rootlineContainerWrap');
    if (rootlineContainerWrap.length > 0) {
        Promise.all([
            import('../scss/_experts-and-consultants.scss'),
            import('./modules/Breadcrumb'),
        ]).then((values) => {
            const Breadcrumb = values[1].default;
            new Breadcrumb();
        });
    }

    // const wplusOptionsContainer = document.querySelectorAll('.wplusOptionsContainer');
    // if (wplusOptionsContainer.length > 0) {
    //     Promise.all([
    //         import('../scss/_servicebox.scss'),
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }

    const relatedCategoriesContainer = document.querySelectorAll('.relatedCategoriesContainer');
    if (relatedCategoriesContainer.length > 0) {
        Promise.all([
            import('../scss/_related-categories.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const myDownloadsSliderWrapper = document.querySelectorAll('.myDownloadsSliderWrapper');
    if (myDownloadsSliderWrapper.length > 0) {
        Promise.all([
            import('./modules/SliderMyDownloads'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper'),
        ]).then((values) => {
            const SliderMyDownloads = values[0].default;
            SliderMyDownloads.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );
            myDownloadsSliderWrapper.forEach((item) => new SliderMyDownloads(item));
        });
    }

    const contactElementContainer = document.querySelectorAll('.contactElementContainer');
    if (contactElementContainer.length > 0) {
        Promise.all([
            import('../scss/_contact-element.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const twoColumnTeaserWithColoredBackgroundContainer = document.querySelectorAll('.twoColumnTeaserWithColoredBackgroundContainer');
    if (twoColumnTeaserWithColoredBackgroundContainer.length > 0) {
        Promise.all([
            import('../scss/_two-column-teaser-with-colored-background.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const stageSliderWrapper = document.querySelectorAll('.stageSliderWrapper');
    if (stageSliderWrapper.length > 0) {
        Promise.all([
            import('./modules/SliderStage'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('swiper/scss/pagination'),
            import('../scss/_stage-slider.scss'),
            import('swiper/scss/effect-creative'),
        ]).then((values) => {
            const SliderStage = values[0].default;
            SliderStage.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[1].Pagination,
                values[1].EffectCreative,
                values[5].default
            );

            stageSliderWrapper.forEach((item) => new SliderStage(item));
        });
    }

    const roundImageWithTextContainer = document.querySelectorAll('.roundImageWithTextContainer');
    if (roundImageWithTextContainer.length > 0) {
        Promise.all([
            import('../scss/_round-image-with-text.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const productInformationContainer = document.querySelectorAll('.productInformationContainer');
    if (productInformationContainer.length > 0) {
        let variants = document.getElementById('variants');
        Promise.all([
            import('../scss/_product-detail.scss'),
            import('./libs/oxid/oxajax.js'),
            import('./libs/oxid/oxarticlevariant.js'),
            import('./libs/oxid/oxrating.js'),
            import('./libs/oxid/oxreview.js'),
            import('./modules/ProductDetail'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('swiper/scss/thumbs'),
            import('swiper/scss/free-mode'),
            import('./libs/easyzoom.js'),
            import('../scss/_easyzoom.scss'),
            import('bootstrap/js/dist/tab'),
        ]).then((values) => {
            $(variants).oxArticleVariant();

            const ProductDetail = values[5].default;
            ProductDetail.inject(
                values[6].Swiper,
                values[6].Navigation,
                values[6].Thumbs,
            );

            new ProductDetail();

            $(document).ready(function() {
                $('.productInformationContainer').each(function() {
                    var container = $(this).find('#productTitle');
                    var text = $(this).find('#productTitle span');
                    if(text.width() > container.width()) {
                        $(this).find('#productTitle').css('hyphens', 'auto');
                    }
                });
            });
        });
    }


    // const contentnavOverlayContainer = document.getElementById('contentnavOverlayContainer');
    // if (contentnavOverlayContainer) {
    //     Promise.all([
    //         import('./modules/ContentNavigation'),
    //         import('../scss/_content-nav.scss'),
    //         import('swiper'),
    //         import('swiper/scss'),
    //         import('swiper/scss/pagination'),
    //     ]).then((values) => {
    //         const ContentNavigation = values[0].default;

    //         ContentNavigation.inject(
    //             values[2].Swiper,
    //             values[2].Pagination,
    //         );

    //         new ContentNavigation();
    //     });
    // }

    // const ratingContainer = document.querySelectorAll('.ratingContainer');
    // if (ratingContainer.length > 0) {
    //     Promise.all([
    //         import('../scss/_rating.scss'),
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }

    const checkoutContainer = document.querySelectorAll('body.page-shoppingbasket');
    if (checkoutContainer.length > 0) {
        Promise.all([
            import('./modules/Checkout'),
            // import('../scss/_checkout.scss')
        ]).then((values) => {
            const Checkout = values[0].default;
            new Checkout();
        });
    }

    const basketContainer = document.querySelectorAll('body.cl-basket');
    if (basketContainer.length > 0) {
        Promise.all([
            import('./libs/oxid/oxremovebasketitems.js'),
        ]).then((values) => {
            $('.removeBasketItems').oxRemoveBasketItems();
        });
    }

    const userContainer = document.querySelectorAll('body.cl-user, body.cl-register, body.cl-register_myevents');
    if (userContainer.length > 0) {
        Promise.all([
            import('./modules/Checkout'),
            // import('../scss/_checkout.scss'),
            import('./libs/oxid/wesvalidateaddresstype.js'),
            import('./libs/oxid/oxusershipingaddressselect.js'),
            import('./libs/oxid/oxselectbox.js'),
            // import('./libs/oxid/oxpayment.min.js')
        ]).then((values) => {
            $('form.js-oxValidate').wesValidateAddressType({formType:'Billing'});
            $('form.js-oxValidate').wesValidateAddressType({formType:'Shipping'});
            $('input[name="oxaddressid"]').oxUserShipingAddressSelect();
            $('.form select').oxSelectBox();
            // $('#payment').oxPayment();
            $('#payment input, #payment select, #payment textarea').not('[type=submit]').jqBootstrapValidation();
            const Checkout = values[0].default;
            new Checkout();
        });
    }

    const articleList = document.querySelectorAll('.articleList');
    if (articleList.length > 0) {
        Promise.all([
            import('./modules/ArticleList'),
            import('../scss/_article-list.scss')
        ]).then((values) => {
            const ArticleList = values[0].default;
            articleList.forEach((item) => new ArticleList(item));
        });
    }

    // const subcategoriesAndFilterContainer = document.querySelectorAll('.subcategoriesAndFilterContainer');
    const categoryAttributeFilters = document.querySelectorAll('.subcategoriesAndFilterContainer .filters');
    // if (subcategoriesAndFilterContainer.length > 0) {
    //     Promise.all([
    //         import('../scss/_subcategories-and-filter.scss')
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }
    if (categoryAttributeFilters.length > 0) {
        Promise.all([
            import('./modules/CategoryAttributeFilters'),
            import('./modules/ArticleList'),
        ]).then((values) => {
            const CategoryAttributeFilters = values[0].default;

            CategoryAttributeFilters.inject(
                values[1].default,
            );

            new CategoryAttributeFilters();
        });
    }

    const twoColumnTeaserContainer = document.querySelectorAll('.two-column-teaser-container');
    if (twoColumnTeaserContainer.length > 0) {
        Promise.all([
            import('../scss/_landing-page.scss')
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const aboSlider = document.querySelectorAll('.aboModelSwiper');
    if (aboSlider.length > 0) {
        Promise.all([
            import('./modules/AboModelSlider'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('../scss/_sticky_elements.scss')
        ]).then((values) => {
            const AboModelSlider = values[0].default;
            AboModelSlider.inject(
                values[1].Swiper,
                values[1].Navigation,
            );
            // aboSlider.forEach((item) => new AboModelSlider(item));
            var sliders = [];
            aboSlider.forEach(function(item) {
                sliders.push(new AboModelSlider(item));
            });

            new AboModelSticky(sliders);

            /*
            sliders.forEach(function(item) {
                console.log('ai:' + item.swiper.activeIndex);
            });
            */

            /*
            if (window.location.href.includes('test-weka')) {
                Promise.all([
                    import('../scss/_sticky_elements.scss')
                ]).then((values) => {
                    new AboModelSticky();
                });
            } */



        });
    }

    // const downloadContainer = document.querySelectorAll('.myAccountDownloadContainer');
    // if(downloadContainer.length > 0) {
    //     Promise.all([
    //         // import('../scss/_account.scss'),
    //         // import('../scss/_downloads.scss')
    //     ]).then((values) => {
    //         // Nothing todo here...
    //     });
    // }

    const seminareLinkContainer = document.querySelectorAll('.cl-account_subscriptions, .cl-account_order, .cl-account_user, .accountMyEventsSeminareLinkContainer');
    if (seminareLinkContainer.length > 0) {
        Promise.all([
            import('./modules/Checkout'),
            // import('../scss/_account.scss'),
            // import('../scss/_checkout.scss'),
            import('./libs/oxid/wesvalidateaddresstype.js'),
            import('./libs/oxid/oxusershipingaddressselect.js')
        ]).then((values) => {
            const Checkout = values[0].default;
            new Checkout();
            $('form.js-oxValidate').wesValidateAddressType({formType:'Shipping'});
            $('form.js-oxValidate').wesValidateAddressType({formType:'Billing'});
            $('input[name="oxaddressid"]').oxUserShipingAddressSelect();
        });
    }

    const thankYou = document.querySelectorAll('body.cl-thankyou');
    if (thankYou.length > 0) {
        Promise.all([
            import('../scss/_upselling.scss')
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const ordersList = document.querySelectorAll('.ordersList');
    if (ordersList.length > 0) {
        Promise.all([
            import('./modules/OrdersList')
        ]).then((values) => {
            const OrdersList = values[0].default;
            ordersList.forEach((item) => new OrdersList(item));
        });
    }

    const videoGeContainer = document.querySelectorAll('.videoGeContainer');
    if (videoGeContainer.length > 0) {
        Promise.all([
            import('../scss/_video.scss'),
        ]).then((values) => {
            // Nothing todo here...
        });
    }

    const solr = document.querySelectorAll('.tx_solr');
    if (solr.length > 0) {
        $('#content').addClass('solr');
    }

    $(document.body).on('click', '#c22348 a, #c22367 a', (e) => {
        e.preventDefault();

        $('#contactModal').modal('toggle');
    });

    if (window.location.hash.toLowerCase() == '#contactmodal') {
        $('#contactModal').modal('toggle');
    }
    if (window.location.hash.toLowerCase() == '#newslettermodal') {
        $('#newsletterModal').modal('toggle');
    }
}