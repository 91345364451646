async function sha256(string) {
    const s = new TextEncoder().encode(string);
    const crypted = await crypto.subtle.digest('SHA-256', s);
    return Array.from(new Uint8Array(crypted))
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
}

async function gaSetUserData(userData) {
    // if (typeof window.dataLayer !== 'undefined') {
    //     let data = { address: {} };

    //     if (userData.email) {
    //         data.email = userData.email;
    //     }
    //     if (userData.firstname) {
    //         data.address.first_name = userData.firstname;
    //     }
    //     if (userData.lastname) {
    //         data.address.last_name = userData.lastname;
    //     }

    //     window.dataLayer.push({ customer: data });
    // }

    if (typeof window.gtag !== 'undefined') {
        let data = { address: {} };

        if (userData.sha256.email) {
            data.sha256_email_address = userData.sha256.email;
        } else if (userData.email) {
            data.sha256_email_address = await sha256(userData.email.trim().toLowerCase());
        }

        if (userData.sha256.firstname) {
            data.address.sha256_first_name = userData.sha256.firstname;
        } else if (userData.firstname) {
            data.address.sha256_first_name = await sha256(userData.firstname.trim().toLowerCase());
        }

        if (userData.sha256.lastname) {
            data.address.sha256_last_name = userData.sha256.lastname;
        } else if (userData.lastname) {
            data.address.sha256_last_name = await sha256(userData.lastname.trim().toLowerCase());
        }

        window.gtag('set', 'user_data', data);
    }
}

export { sha256, gaSetUserData }